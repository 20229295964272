import {
  Black100,
  Blue100,
  Blue800,
  White100,
  Blue400,
  Black400,
} from "../../consts/colors";

const SeeProjectDetailsStyles = {
  headerBox: {
    display: "flex",
    marginTop: "2%",
    marginLeft: "10px",
    justifyContent: "space-between",
    marginRight: "10px",
  },

  projectStatusInlineBoxes: {
    display: "inline-flex", 
    paddingRight: "10px",
  },

  headerLeftBox: {
    display: "flex",
    alignItems: "center", // Center items vertically
    gap: "16px", // Add some space between items
  },
  avatarProject: {
    width: 40,
    height: 40,
    backgroundColor: Blue100,
    borderRadius: "5px",
  },
  graphText: {
    color: "#585858",
    position: "relative",
    fontSize: "14px",
    fontWeight: 600,
    textAlign: "center",
  },
  projectStatusDefaultText: {
    color: "#818181",
    fontWeight: 600,
    fontSize: "14px",
    padding: "2px",
    margin: 0,
  },
  typographyPr: {
    fontFamily: "Poppins",
    fontWeight: "bold",
    fontSize: "14px",
  },
  projectName: {
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: "34px",
    lineHeight: "43.2px",
    letter: "-2.5%",
    color: Blue800,
    marginLeft: "7px",
  },
  projectDetailsBox: {
    marginTop: "0.8%",
    marginLeft: "10px",
  },
  details: {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "18.2px",
    color: Black100,
  },
  main: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "30px",
    marginLeft: "10px",
    marginRight: "10px",
  },

  cardWidth: {
    width: "49%",
  },
  description: {
    height: "180px",
    marginBottom: "2%",
    borderRadius: "10px",
    backgroundColor: White100,
  },
  reviewOwner: {
    height: "180px",
    borderRadius: "10px",
    marginBottom: "2%",
    backgroundColor: White100,
  },
  status: {
    height: "450px",
    marginBottom: "2%",
    borderRadius: "10px",
    backgroundColor: White100,
    "overflow-y": "auto",
  },
  chart: {
    height: "400px",
    borderRadius: "10px",
    backgroundColor: White100,
    marginRight: "10px",
    marginLeft: "8px",
  },
  rhistory: {
    minHeight: "450px",
    borderRadius: "10px",
    backgroundColor: White100,
    marginBottom: "2%",
  },
  titleDescription: {
    paddingBottom: "15px",
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: "22px",
    lineHeight: "28.8px",
    color: Blue400,
    letter: "-1.5%",
  },
  inlineStatusFields: {
    marginLeft: "20px",
    padding: "6px",
    display: "inline-flex",
    width: "100%",
    paddingRight: "16px",
  },
  mainStatusField: {
    padding: "2px",
    fontFamily: "Poppins",
    fontWeight: "bold",
    fontSize: "14px",
    color: Black400,
  },
  statusFields: {
    fontFamily: "Poppins",
    fontWeight: "regular",
    fontSize: "14px",
    color: Black100,
    display: "-webkit-box",
    WebkitLineClamp: 2,
    lineClamp: 2,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    padding: "2px",
  },
  titleBox: {
    display: "flex",
    justifyContent: "space-between",
  },
  statusBox: {
    display: "flex",
    width: "24px",
    height: "24px",
    borderRadius: "8px",
  },
  statusText: {
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "23.4px",
    letter: "-1%",
    color: Blue400,
    marginLeft: "10px",
    marginTop: "2px",
    alignItems: "center",
  },
  sBox: {
    display: "flex",
    justifyContent: "center",
  },
  button: {
    width: "173px",
    height: "44px",
    borderRadius: "8px",
    border: "2px solid " + Blue800,
    backgroundColor: "none",
    fontFamily: "Poppins, sans-serif",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "18px",
    letterSpacing: "-0.005em",
    textAlign: "left",
    color: Blue800,
    "&:hover": {
      backgroundColor: "White",
    },
    textTransform: "capitalize",
  },
  buttonEdit: {
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "18.2px",
    letterSpacing: "-0.5%",
    textTransform: "capitalize",
    color: Black400,
    "&:hover": { backgroundColor: "transparent" },
    marginLeft: "8px",
  },
  tooltipTitle: {
    fontFamily: "Poppins",
    fontWeight: "regular",
    fontSize: "14px",
    color: White100,
    transition: "background-color 0.3s, color 0.3s",
  },
};

export default SeeProjectDetailsStyles;
